<template>
  <div v-if="open" class="spinner_container flex flex-col space-y-2">
    <div class="spinner"></div>
    <p class="text-gray-700">Aguarde...</p>
    <p class="text-gray-700">{{msg}}</p>
  </div>
</template>

<script>
export default {
  props: ["open","msg"],

  data() {
    return {
    };
  },

};
</script>


<style scoped>
.spinner_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
    to {transform: rotate(360deg);}
}


</style>
