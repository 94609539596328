<template>
  <div class="mt-10 px-4">
    <router-link
      v-if="!loading"
      :to="{ 
        name: 'products',
        params: { companyUrl: company.url, uuid: company.uuid }
       }"
      class="flex items-center text-gray-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <span class="text-xs md:text-sm"> ir para página inicial</span>
    </router-link>

    <div class="flex justify-around md:flex-row-reverse flex-col my-5">
      <div class="flex items-center  md:justify-between mt-10 md:block">
        <h3 class=" font-semibold text-gray-700 text-lg flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <span class="text-xs md:text-sm"> Editar endereço</span>
        </h3>
        <router-link
          :to="{ name: 'auth.edit/address' }"
          class="text-gray-700 flex justify-end items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mx-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
          <span class="text-gray-600 text-xs md:text-sm">Clique aqui</span>
        </router-link>
      </div>

      <div class="max-w-md w-full space-y-8">
        <h2 class="mt-10 font-semibold text-gray-700 text-2xl">Editar dados</h2>

        <p class="text-sm text-gray-500">
          O e-mail cadastrado é sua chave no putzfome. Através dele, você é
          reconhecido e realiza suas compras.
        </p>

        <form @submit.prevent="editClient" class="mt-8 space-y-6">
          <div class="rounded-md shadow-sm -space-y-px">
            <div class="pb-6">
              <label for="name" class="sr-only">Nome</label>
              <input
                id="name"
                name="name"
                v-model="formData.name"
                type="name"
                autocomplete="name"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-500
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                :class="{ 'border border-red-600 ': errors.name }"
                placeholder="Nome"
              />
              <div v-if="errors.name != ''" class="text-red-700 text-xs py-1">
                {{ errors.name[0] || "" }}
              </div>
            </div>

            <div class="pb-6">
              <label for="email-address" class="sr-only">Email</label>
              <input
                id="email-address"
                name="email"
                v-model="formData.email"
                type="email"
                autocomplete="email"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-500
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                :class="{ 'border border-red-600 ': errors.email }"
                placeholder="E-mail"
              />
              <div v-if="errors.email != ''" class="text-red-700 text-xs py-1">
                {{ errors.email[0] || "" }}
              </div>
            </div>
            <div class="pb-6">
              <label for="telephone" class="sr-only">Celular</label>
              <the-mask
                :mask="['(0##) #####-####']"
                id="telephone"
                name="telephone"
                v-model="formData.telephone"
                type="telephone"
                autocomplete="telephone"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-500
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                :class="{ 'border border-red-600 ': errors.telephone }"
                placeholder="Celular"
              />
              <div
                v-if="errors.telephone != ''"
                class="text-red-700 text-xs py-1"
              >
                {{ errors.telephone[0] || "" }}
              </div>
            </div>

            <div class="pb-6">
              <label for="password" class="sr-only">Senha</label>
              <input
                id="password"
                name="password"
                v-model="formData.password"
                type="password"
                autocomplete="current-password"
                class="
                  appearance-none
                  rounded
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                "
                :class="{ 'border border-red-600 ': errors.password }"
                placeholder="Senha"
              />
              <div
                v-if="errors.password != ''"
                class="text-red-700 text-xs py-1"
              >
                {{ errors.password[0] || "" }}
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="
                group
                relative
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                text-sm
                font-medium
                rounded-md
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
              :disabled="loading"
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg
                  class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-if="loading" class="flex items-center">
                <img
                  src="@/assets/images/preloader.gif"
                  alt=""
                  class="w-5 h-5 mr-2"
                />
                Um momento
              </span>
              <span v-else>Salvar</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <Loading :open="loading" :msg="msgLoading" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from "../../components/Loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      msgLoading: "Enviando os dados",

      formData: {
        id: null,
        name: "",
        email: "",
        telephone: "",
        password: "",
      },
      errors: {
        name: "",
        email: "",
        telephone: "",
        password: "",
      },
    };
  },
  created() {
    if (!localStorage.getItem("token_sanctum")) {
      this.$router.push({ name: "home" });
      return false;
    }
    this.loading = true;
    this.getMe()
      .then((response) => {
        this.loading = false;
        this.formData.id = response.id;
        this.formData.name = response.name;
        this.formData.email = response.email;
        this.formData.telephone = response.telephone;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
            icon: "error",
            title: "Houve um erro",
          });

          return;
        }
      });
  },

  methods: {
    ...mapActions(["getMe", "edit"]),

    editClient() {
      if (!this.formData.password) {
        this.errors.password = ["Para sua segurança, informe a senha."];
        return false;
      }

      this.reset();

      this.$swal
        .fire({
          title: "Atenção",
          html: '<span class="text-danger">Continuar com a alteração?</span>',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.edit(this.formData)
              .then((response) => {
                this.formData.password = "";
                this.$swal.fire({
                  icon: "success",
                  title: "Alterado com sucesso",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
              })
              .catch((error) => {
                this.errors = Object.assign(
                  this.errors,
                  error.response.data.errors
                );

                // this.$swal.fire("Houve um erro!", error.response.data.message, "error")
                if (error.response.data.message === "Credenciais inválidas") {
                  this.errors.password = [error.response.data.message];
                }
              });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.errors = {
        name: "",
        email: "",
        telephone: "",
        password: "",
      };
    },
  },
  computed: {
    ...mapState({
   
      company: (state) => state.companies.companySelected,

    }),
  },


};
</script>